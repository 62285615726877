<template>
  <v-container>
    <v-content>
      <section>
        <v-layout column align-center justify-center>
          <h1 class="display-4 text-xs-center mt-200">404</h1>
          <h1 class="display-3 text-xs-center">
            {{ $t('message.message_404') }}
          </h1>
          <v-btn class="blue lighten-2 mt-5" dark large :href="url">
            {{ $t('message.button_message_404') }}
          </v-btn>
        </v-layout>
      </section>
    </v-content>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      url: process.env.VUE_APP_SUBDOMAIN_ACCOUNTS_FULL + '/session/login',
    }
  },
}
</script>
